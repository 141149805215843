<template>
    <DxDataGrid
        class="pt-2 table-page"
        id="table_ViPhamTruyenTai_DanhSachBaoCao"
        ref="table_ViPhamTruyenTai_DanhSachBaoCao"
        :show-borders="true"
        :data-source="Params.DataTable"
        :remote-operations="true"
        :allow-column-resizing="true"
        :column-auto-width="false"
        @cellHoverChanged="onCellHoverChanged"
        :onContentReady="onContentReady"
        :loadPanel="{ showIndicator: false, showPane: false, text: '' }"
        key-expr="IdBaoCao"
        height="calc(100vh - 170px)"
    >
        <DxPaging :page-size="parseInt($i18n.t('Settings.PageSize'))" />
        <!-- <DxGrouping :auto-expand-all="false"/> -->
        <DxScrolling
            :mode="$i18n.t('Settings.DxScrolling_Mode')"
            :row-rendering-mode="
                $i18n.t('Settings.DxScrolling_RowRenderingMode')
            "
        />
        <DxColumnFixing :enabled="true" />
        <DxHeaderFilter :visible="true" />
        <slot name="dx-column"></slot>

        <DxColumn
            alignment="left"
            data-field="TenBen"
            caption="Tên bến xe"
            :minWidth="400"
            :fixed="true"
        ></DxColumn>

        <DxColumn
            alignment="left"
            data-field="TenLoai"
            caption="Loại bến xe"
            cell-template="tenloaiben-cells"
            :minWidth="150"
        ></DxColumn>

        <DxColumn
            :allowFiltering="false"
            :allowSorting="false"
            data-field="TongLuotViPham"
            caption="Số ngày truyền tải lỗi dữ liệu (ngày)"
            :minWidth="200"
            data-type="string"
        ></DxColumn>
        <DxColumn caption="Số lượt truyền tải lỗi" alignment="center">
            <DxColumn
                :allowSorting="false"
                :allowFiltering="false"
                data-field="LuotViPhamXB"
                caption="Xuất bến (lượt)"
                :minWidth="150"
            ></DxColumn>

            <DxColumn
                :allowSorting="false"
                :allowFiltering="false"
                data-field="LuotViPhamDB"
                caption="Đến bến (lượt)"
                :minWidth="150"
            ></DxColumn>
        </DxColumn>

        <DxSummary>
            <DxGroupItem
                column="TenLoai"
                summary-type="count"
                display-format="{0} bến"
            />
            <DxGroupItem
                column="TongLuotViPham"
                summary-type="sum"
                :show-in-group-footer="false"
                :align-by-column="true"
                :customize-text="customizeSummary"
            />
            <DxGroupItem
                column="LuotViPhamXB"
                summary-type="sum"
                :show-in-group-footer="false"
                :align-by-column="true"
                :customize-text="customizeSummary"
            />
            <DxGroupItem
                column="LuotViPhamDB"
                summary-type="sum"
                :show-in-group-footer="false"
                :align-by-column="true"
                :customize-text="customizeSummary"
            />

            <DxTotalItem
                column="TenBen"
                summary-type="count"
                :customize-text="customizeTenBenXe"
                :show-in-group-footer="false"
            />

            <DxTotalItem
                column="TongLuotViPham"
                summary-type="sum"
                :show-in-group-footer="false"
                :customize-text="customizeSummary"
                :align-by-column="true"
                alignment="right"
            />
            <DxTotalItem
                column="LuotViPhamXB"
                summary-type="sum"
                :show-in-group-footer="false"
                :customize-text="customizeSummary"
                :align-by-column="true"
            />
            <DxTotalItem
                column="LuotViPhamDB"
                summary-type="sum"
                :show-in-group-footer="false"
                :customize-text="customizeSummary"
                :align-by-column="true"
            />

            /></DxSummary
        >
        <!-- Hover button -->
        <DxColumn
            cssClass="column-button-hover"
            data-field="DataField"
            caption="Custom"
            width="1"
            fixed-position="right"
            :fixed="true"
            cell-template="buttons-cell"
        ></DxColumn>
        <template #buttons-cell="{ data }">
            <div class="buttons">
                <DxButton
                    id
                    icon="mdi mdi-eye"
                    @click="xemChiTiet(data.data)"
                    v-if="
                        $Helper.Common.CheckRole(
                            $i18n.t('Quyen.BaoCaoBenXe'),
                            $i18n.t('Quyen.DanhSachBaoCaoNhatKyTruyenTai')
                        )
                    "
                ></DxButton>
            </div>
        </template>
        <template #tenloaiben-cells="{ data }">
            <div class="tenloai-css">{{ data.data.TenLoai }}</div>
        </template>
        <!--sfds-->
    </DxDataGrid>
</template>
<script>
import { DxDataGrid, DxButton } from "devextreme-vue";
import {
    DxColumn,
    DxPaging,
    DxScrolling,
    DxColumnFixing,
    DxHeaderFilter,
    DxSummary,
    DxGroupItem,
    DxTotalItem,
} from "devextreme-vue/data-grid";

export default {
    components: {
        DxDataGrid,
        DxButton,
        DxColumn,
        DxPaging,
        DxScrolling,
        DxColumnFixing,
        DxHeaderFilter,
        DxSummary,
        DxGroupItem,
        DxTotalItem,
    },
    props: {
        Params: { type: Object, default: {} },
    },
    data() {
        return {};
    },
    methods: {
        customizeTenBenXe(data) {
            return data.value != undefined
                ? "Tổng: (" + data.value + " Bến)"
                : "";
        },
        xemChiTiet(data) {
            let query = {
                IdBenXe: data.IdBenXe,
                DateFrom: this.Params.AboutDays.From,
                DateTo: this.Params.AboutDays.To,
            };
            localStorage.setItem("DataBC_ViPham", JSON.stringify(query));
            this.$router.push("/Bao-Cao-Thong-Ke/Nhat-Ky-Truyen-Tai");
        },
        // onContentReady(e) {
        //     $(e.element)
        //         .find(".dx-datagrid-total-footer")
        //         .css("border-top", 0)
        //         .css("border-bottom", "1px solid #d3d3d3")
        //         .css("height", "48px")
        //         .css("padding-top", "0px")
        //         .insertBefore($(e.element).find(".dx-datagrid-rowsview"));
        // },
        customizeSummary(data) {
            return data.value;
        },
        customizeSummaryGroup(data) {
            return data.value + " bến";
        },
        onCellHoverChanged(e) {
            this.$Helper.Common.table({ Element: e, Buttons: true }).HoverRow();
        },
        dataSourceStatus(data) {
            let self = this;
            data.dataSource.postProcess = (results) => {
                results = self.Params.DataError;
                return results;
            };
        },
    },
    mounted() {
        let self = this;
        this.emitter.on("excel", function () {
            let init = {
                Element:
                    self.$refs.table_ViPhamTruyenTai_DanhSachBaoCao.instance,
                WorkSheet: "Danh Sách",
                FileName: "Bao-Cao-Vi-Pham-Truyen-Tai-Du-Lieu",
            };
            self.$Helper.Common.excel(init).Export();
        });
        this.emitter.on("reload", function () {
            let datagrid =
                self.$refs.table_ViPhamTruyenTai_DanhSachBaoCao.instance;
            datagrid.clearFilter();
        });
    },
    beforeUnmount() {
        this.emitter.off("excel");
    },
};
</script>
<style scoped>
>>> .dx-datagrid-rowsview
    .dx-row.dx-data-row.dx-row-lines
    > td[aria-colindex="5"],
>>> .dx-datagrid-summary-item.dx-datagrid-text-content.dx-datagrid-group-text-content {
    text-align: right !important;
}
>>> .dx-header-row:nth-child(2) > td[aria-colindex="1"],
>>> .dx-header-row:nth-child(2) > td[aria-colindex="2"] {
    text-align: left !important;
}
>>> .tenloai-css {
    border: 1px solid #000;
    border-radius: 50%;
    width: 22px;
    margin: 0 auto;
    text-align: center;
}
</style>