<template>
    <div>
        <DxToolbar :height="56" class="toolbar">
            <DxItem location="before" locate-in-menu="never">
                <template #default>
                    <router :breadcrumbsItems="breadcrumbsItems" />
                </template>
            </DxItem>
            <DxItem location="after">
                <template #default>
                    <search-vue
                        @search="searchs"
                        @model="modelSearch"
                        id="textbox_ViPhamTruyenTai_Timkiem"
                    />
                </template>
            </DxItem>
        </DxToolbar>
        <div class="pl-3">
            <div
                class="row justify-space-between"
                style="border-bottom: 1px solid #ddd"
            >
                <DxValidationGroup ref="formValidation">
                    <div class="row align-center pt-2 pb-4">
                        <span class="mr-2 font-16" id="label_ViPhamTruyenTai_Tu"
                            >Từ</span
                        >
                        <DxDateBox
                            v-model="model.DateFrom"
                            displayFormat="dd/MM/yyyy"
                            dropDownButtonTemplate="customIcon"
                            validationMessageMode="always"
                            id="datebox_ViPhamTruyenTai_TuNgay"
                            class="datebox-baocao"
                            stylingMode="underlined"
                            :useMaskBehavior="true"
                        >
                            <DxValidator>
                                <DxRequiredRule
                                    message="Không được để trống!"
                                />
                                <DxRangeRule
                                    :max="maxDate"
                                    :message="$i18n.t('DefaultString.MaxDate')"
                                />
                                <DxRangeRule
                                    :min="minDate"
                                    :message="$i18n.t('DefaultString.MinDate')"
                                />
                                <DxRangeRule
                                    v-model:max="model.DateTo"
                                    message="Từ ngày phải nhỏ hơn đến ngày"
                                />
                            </DxValidator>
                            <template #customIcon="{}">
                                <DxButton icon="mdi mdi-calendar" />
                            </template>
                        </DxDateBox>
                        <span
                            style="
                                border: 1px solid #707070;
                                height: 2px;
                                width: 10px;
                            "
                            class="mx-2"
                        ></span>
                        <DxDateBox
                            v-model="model.DateTo"
                            validationMessageMode="always"
                            displayFormat="dd/MM/yyyy"
                            dropDownButtonTemplate="customIcon"
                            id="datebox_ViPhamTruyenTai_DenNgay"
                            class="datebox-baocao"
                            stylingMode="underlined"
                            :useMaskBehavior="true"
                        >
                            <DxValidator>
                                <DxRequiredRule
                                    message="Không được để trống!"
                                />
                                <DxRangeRule
                                    :max="maxDate"
                                    :message="$i18n.t('DefaultString.MaxDate')"
                                />
                                <DxRangeRule
                                    :min="minDate"
                                    :message="$i18n.t('DefaultString.MinDate')"
                                />
                                <DxRangeRule
                                    :min="model.DateFrom"
                                    message="Từ ngày phải lớn hơn đến ngày"
                                />
                            </DxValidator>
                            <template #customIcon="{}">
                                <DxButton icon="mdi mdi-calendar" />
                            </template>
                        </DxDateBox>
                        <DxButton
                            class="ml-3"
                            :icon="$i18n.t('icon.search')"
                            text="Lọc"
                            styling-mode="outlined"
                            id="button_ViPhamTruyenTai_Loc"
                            :rtlEnabled="true"
                            @click="validate"
                        />
                    </div>
                </DxValidationGroup>
            </div>
            <toolbar-vue
                :Params="data_from_api"
                @selectionChange="selectionChange"
                @reload="reloadFuc"
            />
            <datatable-vue :Params="data_from_api" />
            <!-- Thông báo -->

            <DxPopup
                v-model:visible="dialogThongBao"
                :drag-enabled="false"
                :close-on-outside-click="false"
                :show-title="false"
                width="450"
                height="auto"
            >
                <NotificationVue :Params="Params" @save="hiddenDialog" />
            </DxPopup>
        </div>
    </div>
</template>
<script>
import moment from "moment";
import router from "@/components/_Common/Components/router";
import ToolbarVue from "@/components/BaoCaoThongKe/BaoCaoViPhamTruyenTaiDuLieu/Toolbar";
import searchVue from "@/components/_Common/Components/Search";
import DatatableVue from "@/components/BaoCaoThongKe/BaoCaoViPhamTruyenTaiDuLieu/Datatable";
import NotificationVue from "@/components/_Common/Popups/Notification.vue";
import DxToolbar, { DxItem } from "devextreme-vue/toolbar";
import { DxDateBox, DxButton, DxValidator, DxPopup } from "devextreme-vue";
import { DxRangeRule, DxRequiredRule } from "devextreme-vue/data-grid";
import { DxValidationGroup } from "devextreme-vue/validation-group";
import { DxCalendarOptions } from "devextreme-vue/date-box";

export default {
    // Permission: $PermText("Danh Sách", "Báo cáo vi phạm truyền tải dữ liệu"),
    layout: "application",
    components: {
        router,
        ToolbarVue,
        DatatableVue,
        searchVue,
        NotificationVue,
        DxToolbar,
        DxItem,
        DxDateBox,
        DxButton,
        DxValidator,
        DxPopup,
        DxRangeRule,
        DxRequiredRule,
        DxValidationGroup,
        DxCalendarOptions,
    },
    data() {
        return {
            url: this.$i18n.t("url.ViPhamTruyenTai_DanhSach"),
            url_capdo: this.$i18n.t("url.CapDoTaiKhoan"),
            url_select_so: this.$i18n.t("url.DungChungDanhSachSo"),
            maxDate: new Date(this.$i18n.t("DefaultNumBer.MaxDate")),
            minDate: new Date(this.$i18n.t("DefaultNumBer.MinDate")),
            id_table: "IdBaoCao",
            SoThuTu: [],
            dialogThongBao: false,
            Params: {
                state: "Error",
                title: "Thông báo!",
                icon: "mdi-alert-circle",
                color: "#D10909",
                message_title: "Đã xảy ra lỗi",
                message: "Vui lòng kiểm tra lại",
                button: "ok",
                button_save: "Đồng ý",
                button_cancel: "Hủy",
            },
            model: {
                DateFrom: new Date(),
                DateTo: new Date(),
                Search: "",
                IdSos: 0,
            },
            btn: [
                {
                    name: "password",
                    location: "after",
                    options: {
                        icon: "mdi mdi-calendar",
                        type: "normal",
                        onClick: () => {},
                    },
                },
            ],
            data_from_api: {
                Type: null,
                DataSelect: {
                    SelectSo: [],
                    SelectBenXe: [],
                },
                DataTable: [],
                DataError: [],
                AboutDays: {
                    From: null,
                    To: null,
                },
            },
            breadcrumbsItems: [
                {
                    id: "breadcrums_ViPhamTruyenTai_BaoCaoThongKe",
                    text: this.$i18n.t("stringRouter.nodeBCTK"),
                    disabled: true,
                    to: "",
                },
                {
                    id: "breadcrums_ViPhamTruyenTai_BCViPhamTruyenTai",
                    text: this.$i18n.t("stringRouter.brancheVPTT"),
                    disabled: true,
                    to: "",
                },
            ],
        };
    },
    methods: {
        async getData() {
            try {
                let self = this;
                let query = {
                    tuNgay: this.$Helper.ConvertToTimeTicks(
                        this.model.DateFrom
                    ),
                    denNgay: this.$Helper.ConvertToTimeTicks(this.model.DateTo),
                    idSos: this.model.IdSos,
                    txtTimKiem: this.model.Search || "",
                    stt: Math.max(...self.SoThuTu) || 1,
                };

                this.data_from_api.DataTable = (
                    await this.$store.dispatch(
                        "BaoCaoThongKe/Get_BaoCaoViPhamTruyenTaiDuLieu",
                        query
                    )
                ).Store;
                this.data_from_api.AboutDays.From = moment(
                    this.model.DateFrom
                ).format("MM/DD/yyyy");
                this.data_from_api.AboutDays.To = moment(
                    this.model.DateTo
                ).format("MM/DD/yyyy");
            } catch (error) {
                console.log(
                    "🚀 ~ file: index.vue ~ line 261 ~ getData ~ error",
                    error
                );
            }
        },
        reloadFuc() {
            this.startValue = new Date();
            this.endValue = new Date();
            this.model = {
                DateFrom: new Date(),
                DateTo: new Date(),
                Search: "",
                IdSos: 0,
            };
            this.getData();
        },
        onContentReady(e) {
            $(e.element)
                .find(".dx-datagrid-total-footer")
                .css("border-top", 0)
                .css("border-bottom", "1px solid #d3d3d3")
                .css("height", "48px")
                .css("padding-top", "0px")
                .insertBefore($(e.element).find(".dx-datagrid-rowsview"));
        },
        searchs() {
            this.getData();
        },
        modelSearch(search) {
            this.model.Search = search;
        },
        selectionChange(data) {
            this.model.IdSos = data.SelectSo;
        },
        hiddenDialog() {
            this.dialogThongBao = false;
        },
        validate(e) {
            let minusResult = this.model.DateTo - this.model.DateFrom;
            if (minusResult / (1000 * 60 * 60 * 24) + 1 > 31) {
                console.log("vafo");
                this.dialogThongBao = true;
                this.Params.message =
                    "Hệ thống chỉ hỗ trợ lập báo cáo trong vòng 31 ngày!";
                return;
            } else {
                let result = this.$refs.formValidation.instance.validate();
                if (!!result.isValid) {
                    this.getData();
                }
            }
        },
    },
    watch: {},
    created() {
        this.data_from_api.Type =
            this.$Core.Auth.TokenParsed?.CauHinhCu?.LoaiDonVi;
    },
    async mounted() {
        // this.getData();
    },
};
</script>
<style>
.datebox-baocao .dx-dropdowneditor-button {
    width: 100%;
}
.datebox-baocao
    .dx-datebox.dx-auto-width.dx-dropdowneditor-button-visible.dx-editor-underlined
    .dx-texteditor-input,
.dx-datebox:not(.dx-texteditor-empty).dx-auto-width.dx-dropdowneditor-button-visible.dx-editor-underlined
    .dx-texteditor-input {
    padding-right: unset;
    max-width: 150px;
}
</style>
<style scoped>
/* >>> #btn-filter.dx-button .dx-button-content {
    flex-direction: row-reverse;
    display: flex;
}
>>> #btn-filter.dx-button.dx-button-has-icon.dx-button-has-text .dx-icon {
    margin-left: 8px;
    margin-right: 0px;
    line-height: 22px;
    font-size: 21px;
} */
>>> .icon-list i {
    color: #0620ee;
}
</style>
